import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React from "react";

const SearchInput = () => {
  return (
    <div className="flex w-52 gap-4 p-2 border border-slate-200 hover:border-slate-600 rounded-md items-center shadow-sm">
      <MagnifyingGlassIcon
        aria-hidden="true"
        className="h-[19px] w-[19px] flex-none text-gray-400"
      />
      <span className="text-sm text-gray-600">Search...</span>
    </div>
  );
};

export default SearchInput;
