import React, { Fragment } from 'react'
import RootLayout from '../components/RootLayout'
import { ChevronDownIcon, EyeIcon } from '@heroicons/react/24/outline';
import teamsData from "../data/team.json";
import areaOfFocus from "../data/area-of-focus.json";
import CTA from '../components/CTA';
import { Popover, Transition } from '@headlessui/react';

const OurTeam = () => {
  return (
    <RootLayout>
      <main className="pt-24 mx-auto max-w-7xl p-6 lg:px-8">
        <div className="flex items-center flex-col">
          <h1 className="text-5xl font-semibold md:w-3/5 w-full text-center font-display">
            Our Team
          </h1>
          <p className="text-center mt-4 md:w-2/3 w-full leading-7 text-md">
            Our diverse team comprises software engineers, data scientists, data
            engineers, machine learning engineers, data architects, statistical
            analysts, financial experts, and consultants, all boasting over a
            decade of industry experience. Each member of our team has received
            training from esteemed universities across the United States.
          </p>
        </div>
        <section className="py-24">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-start">
            {Object.keys(teamsData.team).map((el) => (
              <div
                key={el}
                className="p-4 rounded-md shadow-sm border-2 border-slate-700"
              >
                <div className="flex items-start justify-between">
                  <img
                    src={teamsData.team[el].avatar}
                    alt="our services"
                    className="w-20 h-20 rounded-full object-cover mb-2"
                  />
                  <Popover className="relative">
                    {({ open }) => (
                      <>
                        <Popover.Button className="text-sky-950" style={{color: "blue"}}>
                          Show Certifications
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute md:right-0 -right-10 z-10 mt-3 w-screen max-w-sm transform px-4 sm:px-0 lg:max-w-sm">
                            <div className="bg-white p-3 overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                              <div className="mb-4 text-xl font-semibold text-sky-950">
                                Certifications
                              </div>
                              <ul className="h-52 overflow-auto">
                                {teamsData.team[el].certif.map((el) => (
                                  <li className="border-l-4 border-sky-500 flex items-start gap-4 mb-2 p-2 shadow-sm">
                                    {el.logo != "" ? (
                                      <img
                                        src={el.logo}
                                        alt={el.name}
                                        className="w-24"
                                      />
                                    ) : null}
                                    <h4>{el.name}</h4>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </div>
                <h3 className="text-xl font-semibold text-sky-950 mb-1">
                  {el}
                </h3>
                <p className="text-slate-700 leading-6">
                  {teamsData.team[el].description}
                </p>
              </div>
            ))}
          </div>
        </section>
        <section className="py-10">
          <h1 className="text-5xl m-auto mb-24 font-semibold md:w-3/5 w-full text-center font-display">
            Area of Focus
          </h1>
          <div className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:gris-cols-3">
            {Object.keys(areaOfFocus.data).map((el) => (
              <div className="p-3 rounded-md shadow-md">
                <img
                  src={areaOfFocus.data[el].image}
                  className="rounded-lg w-24"
                  alt={el}
                />
                <h3 className="font-display text-xl mb-2 font-semibold text-sky-950">
                  {el}
                </h3>
                <p className="leading-6">{areaOfFocus.data[el].description}</p>
              </div>
            ))}
          </div>
        </section>
        <CTA />
      </main>
    </RootLayout>
  );
}

export default OurTeam