import React, { useState } from 'react'
import RootLayout from '../components/RootLayout'
import { WhitePrimaryBtn } from '../components/Buttons';
import { Link } from 'react-router-dom';
import Partners from '../components/Partners';
import { isMobile } from 'react-device-detect';
import {Swiper, SwiperSlide } from 'swiper/react';
import technologiesData from "../data/techs.json";
import CTA from '../components/CTA';

const OurPartners = () => {

  const [techData, setTechData] = useState(technologiesData);

  return (
    <RootLayout>
      <div className="mx-auto max-w-7xl p-6 lg:px-8">
        <section className="pt-16 flex md:flex-row flex-col gap-5 text-center">
          <div className="flex-1">
            <img
              src="/images/services-hero.jpg"
              alt="our services"
              className="w-full h-full block object-cover rounded-br-3xl"
            />
          </div>
          <div className="flex-1">
            <h1 className="text-left text-5xl font-semibold w-full font-display">
              Our Partners
            </h1>
            <p className="text-left mt-4 w-full leading-7 text-md">
              Wants to partner with us? There isn’t a single platform that suits
              every problem, so we use, create and contribute to a wide variety
              of open-source tools. We’re not afraid to build new tools when
              none exist.
            </p>

            <p className="text-left mt-4 w-full leading-7 text-md">
              We have a understanding and expertise with partners which enables
              us to build an innovative products. All we do is build scalable
              products in the cloud. Want to know more about our methods? Reach
              out, we are ready to help you through our simplified approach.
            </p>

            <Link
              to="/contact"
              className={`mt-4 block w-max p-2 text-sm rounded-lg bg-sky-950 text-white active:bg-sky-800 hover:bg-sky-900 transition font-semibold `}
            >
              Get Started
            </Link>
          </div>
        </section>
        <div className='py-24'>
        <Partners title="We Work With" />
        </div>
        <section className="pb-12 mx-auto max-w-7xl px-6 lg:px-8">
          <h2 className="text-sky-950 font-display text-center text-4xl font-semibold">
            Technologies
          </h2>
          <p className="text-slate-800 lg:w-2/3 w-full m-auto text-center mt-2">
            There isn’t a single platform that suits every problem, so we use,
            create and contribute to a wide variety of open-source tools. We're
            not afraid to build new tools when none exist.
          </p>
          <div className="mt-10 flex items-center justify-between gap-5">
            <Swiper
              spaceBetween={50}
              slidesPerView={isMobile ? 2 : 6}
              loop={true}
              autoplay={{
                delay: 2000,
              }}
            >
              <SwiperSlide>
                <img className='w-20' src={technologiesData.techs[0]} alt='technologies' />
              </SwiperSlide>
              <SwiperSlide>
                <img className='w-16' src={technologiesData.techs[1]} alt='technologies' />
              </SwiperSlide>
              <SwiperSlide>
                <img className='w-16' src={technologiesData.techs[2]} alt='technologies' />
              </SwiperSlide>
              <SwiperSlide>
                <img src={technologiesData.techs[3]} alt='technologies' />
              </SwiperSlide>
              <SwiperSlide>
                <img className='w-20' src={technologiesData.techs[4]} alt='technologies' />
              </SwiperSlide>
              <SwiperSlide>
                <img src={technologiesData.techs[5]} alt='technologies' />
              </SwiperSlide>
              <SwiperSlide>
                <img className='w-16' src={technologiesData.techs[6]} alt='technologies' />
              </SwiperSlide>
              <SwiperSlide>
                <img src={technologiesData.techs[7]} alt='technologies' />
              </SwiperSlide>
              <SwiperSlide>
                <img src={technologiesData.techs[8]} alt='technologies' />
              </SwiperSlide>
              <SwiperSlide>
                <img className='w-16' src={technologiesData.techs[9]} alt='technologies' />
              </SwiperSlide>
              <SwiperSlide>
                <img src={technologiesData.techs[10]} alt='technologies' />
              </SwiperSlide>
              <SwiperSlide>
                <img className='w-20' src={technologiesData.techs[11]} alt='technologies' />
              </SwiperSlide>
            </Swiper>
          </div>
        </section>
        <CTA/>
      </div>
    </RootLayout>
  );
}

export default OurPartners