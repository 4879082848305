import React from "react";

const Partners = ({title}) => {
  return (
    <div className="bg-sky-950">
      <section className="py-24 mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className=" font-display text-center text-4xl font-semibold text-white">
          {title}
        </h2>
        <p className="lg:w-2/3 w-full m-auto text-center text-slate-200 mt-2">
          There isn’t a single platform that suits every problem, so we use,
          create and contribute to a wide variety of open-source tools. We're
          not afraid to build new tools when none exist.
        </p>
        <div className="mt-4 flex items-center justify-between lg:w-3/4 w-full m-auto gap-5">
          <div className="flex flex-col items-center justify-center w-48 h-48 rounded-full bg-white">
            <img src="images/azure-provider.png" className="w-1/2" />
            <h3 className="text-md font-bold text-slate-900">Azure</h3>
          </div>
          <div className="flex flex-col items-center justify-center w-48 h-48 rounded-full bg-white">
            <img src="images/aws-provider.png" className="w-1/2" />
            <h3 className="text-md font-bold text-slate-900">AWS</h3>
          </div>
          <div className="flex flex-col items-center justify-center w-48 h-48 rounded-full bg-white">
            <img src="images/gcp-provider.png" className="w-1/2" />
            <h3 className="text-md font-bold text-slate-900">GCP</h3>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Partners;
