import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../components/logo/Logo";
import FooterData from "../../data/Footer/footerItem.json";
import FooterLinkItem from "../../components/Footer/FooterLinkItem.jsx";

const Footer = () => {
  return (
    <div className="bg-sky-950">
      <div className="mx-auto max-w-7xl lg:px-8 px-6">
        <div className="flex flex-col md:flex-row items-start gap-15 py-15">
          <div className="md:w-2/5 w-full">
            <div className="mb-2">
              <Logo
                image={`${process.env.PUBLIC_URL}/images/logo/footer-logo.png`}
              />
            </div>
            <div className="footer-widget-content">
              <div className="footer-about">
                <p className="mt-5 leading-7 text-[0.95rem]">
              HardTanh is a comprehensive cloud consulting, Data Science: Machine Learning, Deep Learning, AI, and DevOps firm providing
              strategic, end-to-end solutions for migration, optimization, and creation of cloud-native applications, with an emphasis on
              Artificial Intelligence, seamless integration, security,scalability, and performance.
                </p>
              </div>
            </div>
          </div>

          {FooterData &&
            FooterData.map((single, key) => {
              return (
                <div key={key}>
                  <FooterLinkItem data={single} key={key} />
                </div>
              );
            })}
        </div>

        <div className="row">
          <div className="col">
            <p className="copyright">
              &copy; {new Date().getFullYear()} <strong>HardTanh LLC</strong>,
              All Rights Reserved | File Number: 7399167 | D-U-N-S Number: 11-9104119
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
