import React, { useState } from "react";
import ctaData from "../data/Call-to-action.json";
import { WhitePrimaryBtn } from "../components/Buttons";

const CTA = () => {
  const [ctaContent, setCtaContent] = useState(ctaData);

  return (
    <section className="mx-auto max-w-7xl p-6 lg:px-8">
      <div
        aria-label="call to action"
        className="cta-section flex flex-col items-center md:items-start w-full bg-[conic-gradient(at_right,_var(--tw-gradient-stops))] from-indigo-200 via-slate-600 to-indigo-200 rounded-2xl p-10"
      >
        <h2 className="md:w-2/4 w-full text-center m-auto text-3xl uppercase text-white font-display">
          {ctaContent.Header}
        </h2>
        <p className="md:w-2/4 w-full text-center m-auto text-gray-100">
          {ctaContent.Description}
        </p>
        <WhitePrimaryBtn
          to="/contact"
          className="mt-4 m-auto"
          text={ctaContent["btn-label"]}
        />
      </div>
    </section>
  );
};

export default CTA;
