import React, { useEffect } from "react";
import RootLayout from "../components/RootLayout";
import { useState } from "react";
import { marked } from "marked";
import { useParams } from "react-router-dom";
import fm from "front-matter";
import "../md-styles.css";
import CTA from "../components/CTA";

const ServiceDetails = () => {
  const [body, setBody] = useState("");
  const [attribute, setAttribute] = useState("");

  const { slug } = useParams();

  useEffect(() => {
    // Fetch the Markdown content
    fetch(`/services-details/${slug}.md`)
      .then(response => response.text())
      .then(data => {
        const parsed = fm(data);
        setBody(marked(parsed.body));
        console.log("parsed.body", marked(parsed.body));
        setAttribute(parsed.attributes);
      })
      .catch(error => console.error(error));
  }, [slug]);

  return (
    <>
      <RootLayout>
        <section className="m-auto max-w-7xl p-6 lg:px-8">
          <div className="w-full lg:h-[400px] h-[300px] relative">
            <img
              src={attribute.image}
              alt={attribute.title}
              className="w-full h-full object-cover block rounded-xl"
            />
            <h1 className="lg:text-5xl text-2xl text-sky-950 shadow-lg bg-white p-2 rounded-lg font-display text-center absolute lg:bottom-10 font-semibold lg:left-10 left-2 bottom-2">
              {attribute.title}
            </h1>
          </div>
        </section>
        <section className="m-auto max-w-6xl p-6 lg:px-8">
          <div
            className="markdown"
            dangerouslySetInnerHTML={{ __html: body }}
          />
        </section>
        <CTA />
      </RootLayout>
    </>
  );
};

export default ServiceDetails;
