import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { WhitePrimarySubmit } from "./Buttons";

const Search = props => {
  const { isSearchOpen, setIsSearchOpen, openModal } = props;

  function closeModal() {
    setIsSearchOpen(false);
  }

  return (
    <>
      <Transition appear show={isSearchOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-80 backdrop-blur-sm" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="w-1/2 flex items-center gap-2 justify-center">
                  <input
                    placeholder="Looking for something?"
                    type="text"
                    className="w-[200px] p-3 rounded-lg outline-none focus:ring-2 ring-emerald-500 ring-offset-2"
                  />
                  <WhitePrimarySubmit
                    text="search"
                    className="px-4 py-4 capitalize"
                    hasIcon={true}
                    icon={
                      <MagnifyingGlassIcon className="w-5 h-5 text-black" />
                    }
                  />
                </div>
              </Transition.Child>
              <button
                onClick={() => closeModal()}
                className="transition bg-white absolute top-5 right-5 w-10 h-10 flex items-center justify-center rounded-full"
              >
                <XMarkIcon className="w-5 h-5 text-black" />
              </button>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Search;
