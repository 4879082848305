import React, { useState, Fragment, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  DevicePhoneMobileIcon,
  EnvelopeIcon,
  MagnifyingGlassIcon,
  MoonIcon,
  SunIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import headerData from "../../data/Navbar.json";
import { Link, NavLink } from "react-router-dom";
import { BlackPrimarySubmit } from "../Buttons";
import { FacebookIcon, InstagramIcon, LinkedInIcon } from "../../assets/icons";
// import { AppContext } from "../../context/AppContext";

const MobileHeader = props => {
  const { mobileMenuOpen, setMobileMenuOpen } = props;
  const [headerContent, setHeaderContent] = useState(headerData.Navbar);
  // const { dark, toggleDark } = useContext(AppContext);
  const dark = false;

  // const handleTheme = () => {
  //   toggleDark!()
  // }

  return (
    <>
      <Transition appear show={mobileMenuOpen} as={Fragment}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog
            as="div"
            className="lg:hidden"
            open={mobileMenuOpen}
            onClose={setMobileMenuOpen}
          >
            <div className="fixed inset-0 z-10" />
            <Dialog.Panel
              className={`${
                dark ? "bg-gray-900" : "bg-white"
              } fixed inset-y-0 right-0 z-30 w-full overflow-y-auto px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10`}
            >
              <div className="flex items-center justify-between">
                <Link
                  to={headerContent.Logo.Link}
                  className="-m-1.5 p-1.5 text-xl text-white font-bold"
                >
                  <img src="/assets/logo.png" alt="company logo" />
                </Link>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon
                    className={`h-6 w-6 ${dark ? "text-white" : ""}`}
                    aria-hidden="true"
                  />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    {headerContent.MenuItems.map(item => (
                      <NavLink
                        to={item.Link}
                        className={({ isActive }) =>
                          isActive
                            ? "block text-sky-500 text-lg mb-5 font-semibold leading-6"
                            : "block hover:text-sky-400 text-lg mb-5 font-semibold leading-6 text-gray-900"
                        }
                      >
                        {item.Text}
                      </NavLink>
                    ))}
                  </div>
                  <div>
                    <div className="header-contact mt-5">
                      <div className="flex items-center gap-2 text-black text-md mb-2">
                        <DevicePhoneMobileIcon className="w-5 h-5 text-black" />
                        <a href="tel:(+1) 571-677-2959">(+1) 571-677-2959</a>
                      </div>
                      <div className="flex items-center gap-2 text-black text-md mb-2">
                        <EnvelopeIcon className="w-5 h-5 text-black" />
                        <a href="mailto:info@hardtanh.com">info@hardtanh.com</a>
                      </div>
                    </div>
                    <div className="flex items-center gap-4 mb-5">
                      <a href="#">
                        <FacebookIcon className="text-xl text-black" />
                      </a>
                      <a href="#">
                        <InstagramIcon className="text-xl text-black" />
                      </a>
                      <a href="#">
                        <LinkedInIcon className="text-xl text-black" />
                      </a>
                    </div>
                  </div>
                  <div className="py-6">
                    <div className="w-full flex items-center gap-2 justify-center">
                      <input
                        placeholder="Looking for something?"
                        type="text"
                        className="w-full p-2 rounded-lg outline-none border-2 border-gray-900 focus:ring-2 ring-sky-500 ring-offset-2"
                      />
                      <BlackPrimarySubmit
                        text="search"
                        className={`${
                          dark ? "bg-white text-gray-900" : ""
                        } text-sm px-3 py-3 capitalize`}
                        hasIcon={true}
                        icon={
                          <MagnifyingGlassIcon
                            className={`${
                              dark ? "text-gray-900" : "text-white"
                            } w-5 h-5`}
                          />
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </Transition.Child>
      </Transition>
    </>
  );
};

export default MobileHeader;
