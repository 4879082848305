import React, { useState, useContext, useEffect } from "react";
import {
  Bars3Icon,
  DevicePhoneMobileIcon,
  EnvelopeIcon,
  MagnifyingGlassIcon,
  MoonIcon,
  SunIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import MobileHeader from "./MobileHeader";
import headerData from "../../data/Navbar.json";
// import { PrimaryBtnLink, WhiteBorderLink } from "./Button
import { NavLink, Link } from "react-router-dom";
import Search from "../Search";
import { useLocation } from "react-router-dom";
import SearchInput from "../SearchInput";
import { PrimaryBtnLink } from "../Buttons";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  PhoneIcon,
  TwitterIcon,
} from "../../assets/icons";

import capability from "../../dummy.pdf"

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [headerContent, setHeaderContent] = useState(headerData.Navbar);
  let [isSearchOpen, setIsSearchOpen] = useState(false);

  const location = useLocation();

  function openModal() {
    setIsSearchOpen(true);
  }

  return (
    <>
      <header className="w-full sticky top-0 left-0 bg-white shadow-md z-20">
        <div className="bg-sky-950 p-[7px] hidden md:block">
          <div className="mx-auto max-w-7xl flex justify-between items-center px-6 lg:px-8">
            <div className="header-contact flex items-center gap-5">
              <div className="flex items-center gap-2 text-white text-xs">
                <DevicePhoneMobileIcon className="w-4 h-4 text-white" />
                <a href="tel:(+1) 571-677-2959">(+1) 330-689-8199</a>
              </div>
              <div className="flex items-center gap-2 text-white text-xs">
                <EnvelopeIcon className="w-4 h-4 text-white" />
                <a href="mailto:info@hardtanh.com">info@hardtanh.com</a>
              </div>
            </div>
            <div className="flex items-center gap-4">
              <a target="_blank" href="https://www.facebook.com/hardtanh/">
                <FacebookIcon className="text-xl text-white" />
              </a>
              <a target="_blank" href="https://instagram.com/hardtanh">
                <InstagramIcon className="text-xl text-white" />
              </a>
              <a target="_blank" href="https://www.linkedin.com/company/hardtanh">
                <LinkedInIcon className="text-xl text-white" />
              </a>
              <a target="_blank" href="https://www.x.com/hardtanh/">
                <TwitterIcon className="text-xl text-white" />
              </a>
            </div>
          </div>
        </div>
        <nav
          className="mx-auto flex max-w-7xl items-center justify-between p-6 py-4 lg:px-8"
          aria-label="Global"
        >
          <div className="flex flex-1 gap-24 justify-between lg:justify-normal lg:w-fit w-full">
            <div className="flex lg:flex-1">
              <Link
                to={headerContent.Logo.Link}
                className="-m-1.5 p-1.5 text-xl text-gray-800 font-bold"
              >
                <img src="/assets/logo.png" alt="company logo" />
              </Link>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon
                  className="h-6 w-6 text-gray-900"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
          <ul className="hidden w-2/3 lg:flex lg:gap-x-12 items-center justify-center">
            {headerContent.MenuItems.map(items => (
                <li key={items.Link}>
                <NavLink
                  to={items.Link}
                  download={items.download ? items.download : false}
                  className={({ isActive }) =>
                    isActive
                      ? "text-sky-500 text-sm font-semibold leading-6"
                      : "hover:text-sky-400 text-sm font-semibold leading-6 text-gray-900"
                  }
                >
                  {items.Text}
                </NavLink>
              </li>
            ))}
            {/* <li>
              <a href={capability} download className="hover:text-sky-400 text-sm font-semibold leading-6 text-gray-900">
                Our Capability
              </a>
            </li> */}
          </ul>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end gap-2 lg:items-center">
            <button disabled aria-label="search button">
              <SearchInput />
            </button>
            {/* {location.pathname !== "/contact" ? (
              <PrimaryBtnLink
                className="text-white bg-sky-500"
                to={headerContent.CTAButton.Link}
                text={headerContent.CTAButton.Text}
              />
            ) : null} */}
          </div>
        </nav>
        <MobileHeader
          setMobileMenuOpen={setMobileMenuOpen}
          mobileMenuOpen={mobileMenuOpen}
        />
        <Search
          isSearchOpen={isSearchOpen}
          setIsSearchOpen={setIsSearchOpen}
          openModal={openModal}
        />
      </header>
    </>
  );
};

export default Header;
