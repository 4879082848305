import React,{useState} from "react";
import CTA from "../components/CTA";
import RootLayout from "../components/RootLayout";
import technologiesData from "../data/technologies.json";
import { Accordion, AccordionBody, AccordionHeader } from "@material-tailwind/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { isMobile } from "react-device-detect";
import ourTechs from "../data/techs.json";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";

const Technologies = () => {

    const [techData, setTechData] = useState(technologiesData);
  const [open, setOpen] = useState(0);
  const handleOpen = value => setOpen(open === value ? 0 : value);

  return (
    <RootLayout>
      <main className="mx-auto max-w-7xl p-6 lg:px-8">
        <section className="pt-16 flex md:flex-row flex-col gap-5 text-center">
          <div className="flex-1">
            <img
              src="/images/services-hero.jpg"
              alt="our services"
              className="w-full h-full block object-cover rounded-br-3xl"
            />
          </div>
          <div className="flex-1">
            <h1 className="text-left text-5xl font-semibold w-full font-display">
              Our Technologies
            </h1>
            <p className="text-left mt-4 w-full leading-7 text-md">
              Technologies HardTanh is a company that specializes in data-driven
              solutions and technology tools. We use a diverse range of tools to
              collect, process, analyze, and visualize data to provide
              actionable intelligence for our clients. Our expertise in data
              analytics is complemented by a talented team with deep domain
              knowledge. We continuously integrate new tools to stay at the
              forefront of the evolving data landscape.
            </p>

            <p className="text-left mt-4 w-full leading-7 text-md">
              At HardTanh, data is the driving force behind informed
              decision-making and profound insights in today's digital age. Our
              approach is built upon carefully selecting technology tools
              tailored to meet the specific needs of clients and industry
              challenges. Whether the goal is discovering hidden patterns,
              predicting future trends, or optimizing operational efficiency,
              HardTanh's technology arsenal empowers us to deliver tangible
              results that propel businesses forward.
            </p>
          </div>
        </section>

        <section className="py-24 md:flex-row flex-col flex gap-5 text-center">
          <div className="flex-1">
            <h1 className="text-left text-5xl font-semibold w-full font-display">
              Our comprehensive toolkit
            </h1>
            <p className="text-left mt-4 w-full leading-7 text-md">
              Our comprehensive toolkit covers the entire spectrum of data
              analytics, from collecting and integrating data to in-depth
              analysis and advanced modeling. We go beyond data crunching,
              presenting findings in visually compelling ways. What sets us
              apart is not only our technological proficiency but also our
              team's deep domain knowledge, ensuring that the tools we use are
              effectively applied to address the intricate demands of each
              project.
            </p>

            <p className="text-left mt-4 w-full leading-7 text-md">
              HardTanh believes that data is more than just information; it's a
              catalyst for transformation. Our commitment to excellence stems
              from the synergy between human expertise and technological
              innovation. This commitment guarantees that the solutions we
              provide are not only insightful but also actionable. As the data
              landscape evolves, HardTanh evolves with it, continually
              integrating new tools to expand our capabilities and enhance the
              data experiences of our clients.
            </p>
          </div>
          <div className="flex-1">
            <img
              src="/images/services-hero.jpg"
              alt="our services"
              className="w-full h-full block object-cover rounded-br-3xl"
            />
          </div>
        </section>

        <section className="pb-24 flex md:flex-row flex-col gap-5">
          <div className="flex-1">
            <h2 className="font-display text-3xl text-sky-950">
              Why Choose HardTanh
            </h2>
            <p className="m-auto mt-4 w-full leading-7 text-md">
              Choosing HardTanh as your data partner means embarking on a
              collaborative adventure where raw data is transformed into
              meaningful narratives. This collaboration empowers you to make
              decisions with unparalleled confidence, harnessing the full
              potential of data in today's fast-paced digital world. We invite
              you to explore our technology toolkit and join us in deciphering
              the language of data to drive your business forward.
            </p>
          </div>
          <div className="flex-1">
            {Object.keys(techData).map((el, index) => (
              <div className="mb-2">
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between rounded-lg bg-sky-100 px-4 py-2 text-left text-sm font-medium text-sky-900 hover:bg-sky-200 focus:outline-none focus-visible:ring focus-visible:ring-sky-500 focus-visible:ring-opacity-75">
                        <span>{el}</span>
                        <ChevronUpIcon
                          className={`${
                            open ? "rotate-180 transform" : ""
                          } h-4 w-4 text-sky-500`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                        <ul className=" list-disc ml-2">
                          {
                            techData[el].map(el => (
                              <li className="mb-2 text-sky-950">{el}</li>
                            ))
                          }
                        </ul>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            ))}
          </div>
        </section>
        <section className="pb-12 mx-auto max-w-7xl px-6 lg:px-8">
          <h2 className="text-sky-950 font-display text-center text-4xl font-semibold">
            Technologies
          </h2>
          <p className="text-slate-800 lg:w-2/3 w-full m-auto text-center mt-2">
            There isn’t a single platform that suits every problem, so we use,
            create and contribute to a wide variety of open-source tools. We're
            not afraid to build new tools when none exist.
          </p>
          <div className="mt-10 flex items-center justify-between gap-5">
            <Swiper
              spaceBetween={50}
              slidesPerView={isMobile ? 2 : 6}
              loop={true}
              autoplay={{
                delay: 2000,
              }}
            >
              <SwiperSlide>
                <img
                  className="w-20"
                  src={ourTechs.techs[0]}
                  alt="technologies"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="w-16"
                  src={ourTechs.techs[1]}
                  alt="technologies"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="w-16"
                  src={ourTechs.techs[2]}
                  alt="technologies"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img src={ourTechs.techs[3]} alt="technologies" />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="w-20"
                  src={ourTechs.techs[4]}
                  alt="technologies"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img src={ourTechs.techs[5]} alt="technologies" />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="w-16"
                  src={ourTechs.techs[6]}
                  alt="technologies"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img src={ourTechs.techs[7]} alt="technologies" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={ourTechs.techs[8]} alt="technologies" />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="w-16"
                  src={ourTechs.techs[9]}
                  alt="technologies"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img src={ourTechs.techs[10]} alt="technologies" />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="w-20"
                  src={ourTechs.techs[11]}
                  alt="technologies"
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </section>
        <CTA />
      </main>
    </RootLayout>
  );
};

export default Technologies;
