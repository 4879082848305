import React from "react";
import Header from "./header/Header.jsx";
import Footer from "../container/Footer/Footer.js";

const RootLayout = props => {
  const { children } = props;

  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default RootLayout;
